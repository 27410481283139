import React from 'react';
import { get } from 'lodash';
import { usePropertyActionState, useAuthState } from "../../../../../../services";
import NoActions from "../../../../progress/NoActions";
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import MakeOfferBtn from '../../../../property/components/makeOffer';
import ListItem from "./list"

const Content = (props) => {
    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const offerData = state?.propertyOffers || [];
    const noRecord = (!state.loading && !offerData.length);
    const propertyImage = props.property.property_detail?.thumbnail;
    const actionTitle = props?.offer?.actionTitle || 'Offers';
    const listDisclaimer = props?.offer?.listDisclaimer || '';
    const showOfferBtn = get(props, `${props.role || ''}.tabOfferOfferBtn`, false);
    const showAddIcon = get(props, `${props.role || ''}.showAddIcon`, false);

    const reloadDataFun = React.useCallback(() => {
        if (props?.role && !(['tenant', 'buyer'].includes(props?.role)))
            authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = () => {
        services.getPropertyOffers({property_id:props.property.property_detail.crm_id});
    }

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading && !offerData.length) {
        return <ActionsSkeletonWithMore title />;
    }

    return (
        <>
            {showOfferBtn && (
                <MakeOfferBtn hasOffer={!!offerData.length} addIcon={true} showAddIcon={showAddIcon} property={props.property.property_detail} reloadDataFun={reloadDataFun} />
            )}

            <ListItem
                noRecord={noRecord}
                data={offerData}
                image={propertyImage}
                title={`${actionTitle}`}
                listDisclaimer={listDisclaimer}
                reloadDataFun={reloadDataFun}
            />
        </>
        
    )
}

export default Content;
